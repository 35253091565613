<template>
  <div class="sub-menu justify-content-center navbar-light">
    <ul class="nav nav-pills flex-column flex-sm-row">
      <li class="nav-item">
        <router-link
          :to="{ name: 'OfferingDetails', params: { id: id } }"
          class="flex-sm-fill text-sm-center nav-link"
          :class="{ active: selected === 'details' }"
          >Details</router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          :to="{ name: 'OfferingPitch', params: { id: id } }"
          class="flex-sm-fill text-sm-center nav-link"
          :class="{ active: selected === 'pitch' }"
          >Pitch</router-link
        >
      </li>

      <li class="nav-item">
        <router-link
          :to="{ name: 'OfferingTeam', params: { id: id } }"
          class="flex-sm-fill text-sm-center nav-link"
          :class="{ active: selected === 'team' }"
          >Team</router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          :to="{ name: 'OfferingDocuments', params: { id: id } }"
          class="flex-sm-fill text-sm-center nav-link"
          :class="{ active: selected === 'documents' }"
          >Documents</router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          :to="{ name: 'OfferingUpdates', params: { id: id } }"
          class="flex-sm-fill text-sm-center nav-link"
          :class="{ active: selected === 'updates' }"
          >Updates</router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          :to="{ name: 'OfferingPress', params: { id: id } }"
          class="flex-sm-fill text-sm-center nav-link"
          :class="{ active: selected === 'press' }"
          >Press</router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          :to="{ name: 'OfferingComments', params: { id: id } }"
          class="flex-sm-fill text-sm-center nav-link"
          :class="{ active: selected === 'comments' }"
          >Q&amp;A</router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          :to="{ name: 'OfferingInvestments', params: { id: id } }"
          class="flex-sm-fill text-sm-center nav-link"
          :class="{ active: selected === 'investments' }"
          >Investments</router-link
        >
      </li>
    </ul>
  </div>
</template>


<script>
export default {
  props: ["selected", "id"]
};
</script>

<style>
.sub-menu {
  display: flex;
  top: 3.8em;
  background-color: white;
}
</style>
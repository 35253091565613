<template>
  <SubNav class="subnav-position" :selected="'press'" :id="id" />
  <main>
    <div v-if="offeringPress" class="subnav-detail">
      <div class="float-right">
        <router-link
          class="btn btn-primary"
          :to="{ name: 'CreatePress', params: { id: id } }"
        >
          Create Press Clipping
        </router-link>
      </div>
      <div class="error">{{ error }}</div>
      <div v-for="press in offeringPress" :key="press.id">
        <router-link
          :to="{ name: 'EditPress', params: { id: id, pressId: press.id } }"
        >
          <div class="row single">
            <div class="col">
              <div class="subnav-thumbnail">
                <img :src="press.pressLogoURL" />
              </div>
            </div>
            <div class="col">
              <div class="subject">
                {{ press.title }}
              </div>
              <div class="created-at">
                {{ press.date }}
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </main>
  <Footer v-if="offeringPress" />
</template>

<script>
import SubNav from "@/components/navigation/SubNav.vue";
import { ref } from "@vue/reactivity";
import getCollection from "@/composables/getCollection";
import Footer from "@/components/navigation/Footer.vue";

export default {
  props: ["id"],
  components: { SubNav, Footer },
  setup(props) {
    const isPending = ref(false);
    const { error, documents: offeringPress } = getCollection(
      "offeringPress",
      [["offeringId", "==", props.id]],
      ["date", "desc"]
    );

    return { isPending, error, offeringPress };
  }
};
</script>

<style>
</style>